import React, { useContext, useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import Logo from '../images/adobe-logo.inline.svg';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { navigate } from "gatsby"
import ProgressBar from '../components/progress-bar'
import Dropdown from 'react-accessible-dropdown';
import { Helmet } from "react-helmet";
import { getContinueLink, getResults } from '../actions/quiz';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import FooterPolicies from '../components/footer-policies';

import {
  GlobalStateContext,
  GlobalDispatchContext  
} from "../context/GlobalContextProvider"

type DropdownValue = {
  value: string,
  label: string
}

interface Props {
    pageContext: any
    location: any
}

const FinalPage = ({pageContext, location }:Props) => {
    const { title, translation, route, formData, lang, langCode, settings } = pageContext;
    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);
    const { answers, completed,  eventsTriggered } = state;
    const [ answered, setAnswered ] = useState(false);
    const [ market, setMarket ] = useState<string>(null);
    const [ industry, setIndustry ] = useState<string>("");
    const [ jobType, setJobType ] = useState<string>("");
    const marketRef = useRef(null);
    const industryRef = useRef(null);
    const jobTypeRef = useRef(null);

    const previousLink = `${route}question/15`;
    const markets = formData.markets;
    const industries = formData.industries;
    const jobsTypes = formData.jobTypes;
    const onSelectMarket = (data:DropdownValue) => {
        marketRef.current.dropdownButton.classList.remove('error');
        setMarket(data.value);
    }
    const onSelectIndustry = (data:DropdownValue) => {
        industryRef.current.dropdownButton.classList.remove('error');
        setIndustry(data.value);
    }
    const onSelectJobType = (data:DropdownValue) => {
        jobTypeRef.current.dropdownButton.classList.remove('error');
        setJobType(data.value);
    }
    const pushEvents = async () => {
        if (market) {
            await trackCustomEvent({
            // string - required - The object that was interacted with (e.g.video)
            category: "Form",
            // string - required - Type of interaction (e.g. 'play')
            action: "Market",
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label: market,
            // number - optional - Numeric value associated with the event. (e.g. A product ID)
            value: 1
            })
        }
        if (industry) {
            await trackCustomEvent({
            // string - required - The object that was interacted with (e.g.video)
            category: "Form",
            // string - required - Type of interaction (e.g. 'play')
            action: "Industry",
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label: industry,
            // number - optional - Numeric value associated with the event. (e.g. A product ID)
            value: 1
            })
        }
        if (jobType) {
            await trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "Form",
                // string - required - Type of interaction (e.g. 'play')
                action: "Job Type",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: jobType,
                // number - optional - Numeric value associated with the event. (e.g. A product ID)
                value: 1
            })
        }
        if (!market && !industry && !jobType) {
            await trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "Form",
                // string - required - Type of interaction (e.g. 'play')
                action: "Skiped",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: "No optiones selected",
                // number - optional - Numeric value associated with the event. (e.g. A product ID)
                value: 0
            })
        }
    }
    const getMyResults = async() => {
        if (answers && Object.keys(answers).length == 15) {
            await dispatch({
                type: 'COMPLETED'
            });
        }
        setAnswered(true);
    }
    useEffect(() => {
        document.documentElement.classList.add('in');
    },[]);
    const saveToSpreadsheet = async () => {
        const values:string[] = [];
        Object.keys(answers).forEach((slug) => {
            values.push(answers[slug].score.toString());
        });
        values.push(market);
        values.push(industry);
        values.push(jobType);
        
        const { profile_handle, high_rank_area_handle, low_rank_area_handle } = getResults(answers);
        values.push(profile_handle);
        values.push(high_rank_area_handle);
        values.push(low_rank_area_handle);
        let domain = typeof window !== 'undefined' ? window.location.hostname : '';
        if (lang !== '') {
            domain = `${domain}/${lang}`;
        }
        values.push(domain);
        
        await trackCustomEvent({
            // string - required - The object that was interacted with (e.g.video)
            category: "Results",
            // string - required - Type of interaction (e.g. 'play')
            action: "Report",
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label: values.join(','),
            // number - optional - Numeric value associated with the event. (e.g. A product ID)
            value: 1
        });

        continueToResults();
  }
  const continueToResults = () => {
    document.documentElement.classList.remove('in');
    if (answers && Object.keys(answers).length === 15) {
        const { profile_handle } = getResults(answers);
        setTimeout(() => {
            document.body.classList.add(profile_handle);
        }, 500)
        setTimeout(() => {
            navigate(getContinueLink(route, answers, completed));
        }, 800)
    } else {
        navigate(getContinueLink(route, answers, false));
    }
  }
  useEffect(() => {
    if (answered && completed) {
        if (completed && !eventsTriggered) {
                pushEvents();
                saveToSpreadsheet();
        } else {
                continueToResults();
        }
      
    }
  },[answered, completed]);

    const FooterContent = () => (<>
      <Link to={ previousLink } className="link"><span>{ translation.general.back }</span></Link>
        <ProgressBar percentageNumber={ 100 }>
            <FooterPolicies translation={translation}/>
        </ProgressBar>
        <a href="https://adobe.com" target="_blank" className="footer__logo" aria-label={ translation.general.go_to_adobe_website }><Logo/></a>
  </>);
    return (
      <Layout pathname={ location.pathname} socialLinks={settings.social_media} lang={ lang } route={route} translation={ translation } slug="final" footerContent={FooterContent} footerClassName="footer--with-progress">
        <Helmet bodyAttributes={{ class: `final-page lang-${lang}` }}/>
        <SEO lang={langCode} title={ title } />
        <div className="final">
          <div className="container">
              <div className="final__content">
                <h1 className="final__title h2">{ translation.general.tell_us_about_you}</h1>
                <div className="final__dropdowns">
                    { markets.length > 0 && <Dropdown ref={marketRef} options={markets} value={market} onChange={onSelectMarket} placeholder={ translation.general.market } /> }
                    { industries.length > 0 && <Dropdown ref={industryRef} options={industries} value={industry} onChange={onSelectIndustry} placeholder={ translation.general.industry } />}
                    { jobsTypes.length > 0 && <Dropdown ref={jobTypeRef} options={jobsTypes} value={jobType} onChange={onSelectJobType} placeholder={ translation.general.job_type } />}
                </div>
                <div className="final__button">
                    <button className="button button--large" onClick={() => getMyResults() }>{ translation.general.get_my_results }</button>
                </div>
              </div>
          </div>
        </div>
      </Layout>
    )
  }

export default FinalPage
